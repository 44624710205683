export const cart_service = 'https://cs.scootland.cz/api/v1/';
export const general_service = 'https://general.scootland.cz/api/v1/';
export const cartStorageKey = 'cartStorage';
export const scooterStorageKey = 'scooterStorage';
export const preferencesStorageKey = 'preferences';
//The price of free shipping for the cart
export const cartFreeDelivery = 1500;
// TODO: implement
export const max_price = 1000000;
export const max_products_count = 100;
  